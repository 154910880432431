<script>
    import { required } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            userData: {
                type: Object,
                default: null
            },
        },
        watch: {
            userData: function(newVal) {
                this.id_e = newVal.id;
                this.first_name_e = newVal.first_name;
                this.nickname_e = newVal.nickname;
                this.middle_name_e = newVal.middle_name;
                this.last_name_e = newVal.last_name;
                this.gender_e = newVal.gender;
                this.date_of_birth_e = newVal.date_of_birth;
            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                id_e: '',
                first_name_e: '',
                nickname_e: '',
                last_name_e: '',
                middle_name_e: '',
                gender_e: '',
                date_of_birth_e: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                gender_options: [
                    { item: 'M', name: 'Male' },
                    { item: 'F', name: 'Female' },
                    { item: '', name: 'Unknown' },
                ],
            };
        },
        validations: {
            first_name_e: {
                required,
            },
            last_name_e: {
                required,
            },
        },
        methods: {

            async editCustomer(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Customers.updateCustomer(this.id_e,{
                            first_name: this.first_name_e,
                            nickname: this.nickname_e,
                            middle_name: this.middle_name_e,
                            last_name: this.last_name_e,
                            gender: this.gender_e,
                            date_of_birth: this.date_of_birth_e,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Change successfully saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Failed to save changes")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.submitted = false
            }
        }
    };
</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="user_edit" title="Edit Customer" title-class="font-18" centered>
        <form @submit.prevent="editCustomer">

            <b-form-group label="First Name" label-for="formrow-firstname-input" class="mb-3">
                <b-form-input
                        v-model.trim="first_name_e"
                        type="text"
                        id="first_name_e"
                        aria-describedby="first_name_e-feedback"
                        :class="{
                      'is-invalid': submitted && $v.first_name_e.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.first_name_e"></validationMessages>
            </b-form-group>

            <b-form-group label="Middle Name" label-for="formrow-middle_name_e-input" class="mb-3">
                <b-form-input
                        v-model.trim="middle_name_e"
                        type="text"
                        id="middle_name_e"
                        aria-describedby="middle_name_e-feedback"
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Last Name" label-for="formrow-last_name_e-input" class="mb-3">
                <b-form-input
                        v-model.trim="last_name_e"
                        type="text"
                        id="last_name_e"
                        aria-describedby="last_name_e-feedback"
                        :class="{
                      'is-invalid': submitted && $v.last_name_e.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.last_name_e"></validationMessages>
            </b-form-group>

            <b-form-group label="Gender" label-for="formrow-gender-input" class="mb-3">
                <b-form-radio-group
                        v-model="gender_e"
                        :options="gender_options"
                        value-field="item"
                        text-field="name"
                        name="radio-inline"
                        inline
                ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Date of Birth" label-for="date_of_birth_e" class="mb-3">
                <b-form-input
                        id="date_of_birth_e"
                        v-model="date_of_birth_e"
                        type="date"
                ></b-form-input>
            </b-form-group>
          <b-form-group label="Nickname" label-for="formrow-nickname-input" class="mb-3">
            <b-form-input
                v-model.trim="nickname_e"
                type="text"
                id="first_name_e"
                aria-describedby="nickname_e-feedback"
            >
            </b-form-input>
          </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editCustomer" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>