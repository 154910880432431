<script>
    import { required } from "vuelidate/lib/validators";
    import Notification from '@/services/Notification';
    import Content from '@/services/Content';
    import Swal from "sweetalert2";
    import validationMessages from '@/components/validations'
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
    import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
    import moment from "moment";

    export default {
        components:{
            flatPickr,
            validationMessages
        },
        props: {
            userData: {
                type: Object,
                default: null
            },
        },
        watch: {
            userData: function(newVal) {
                this.id_e = newVal.id;
            }
        },
        data() {
            return {
                notification_languages: JSON.parse(process.env.VUE_APP_NOTIFICATION_LANG),
                csrf_token: localStorage.getItem('csrf_token'),
                id_e: '',
                notificationTypes:[],
                message_type:'',
                title:'',
                message:'',
                showSchedule: false,
                schedule_time: '',
                language:'',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                showLoader:false,
                datetimeconfig: {
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: "today",
                    minuteIncrement: 1,
                    defaultDate: new Date(),
                    plugins: [new ConfirmDatePlugin({})]
                },
                deepLinkingAction:'',
                deepLinkingActionNews:'',
                showDeepLinkingActionNews: false,
                deepLinkingActionVideos:'',
                showDeepLinkingActionVideos: false,
                deepLinkingActionMDC:'',
                showDeepLinkingActionMDC: false,
                deepLinkingFullScreen:'Yes',
                deepLinkActions:[
                    { "action": "news", "name": "News" },
                    { "action": "matchcenter", "name": "Match Center" },
                    { "action": "mdc", "name": "Match Day Challenge" },
                    { "action": "videos", "name": "Videos" },
                    { "action": "tickets", "name": "Tickets" },
                    { "action": "merchandise", "name": "Merchandise" },
                    { "action": "deals", "name": "Club Deals" },
                    { "action": "teams", "name": "Teams" },
                    { "action": "minigames", "name": "Minigames" }
                ],
                deepLinkActionsNews:[],
                deepLinkActionsVideos:[],
                deepLinkActionsMDC:[],
                deepLinkFullScreen:[
                    { "key": true, "value": "Yes" },
//                    { "key": false, "value": "No" },
                ],
            };
        },
        validations: {
            message:{
                required
            },
        },
        methods: {
            async getNotificationTypes() {
                try {
                    this.showLoader = true;
                    await Notification.getNotificationTypes({

                    }).then((response) => {
                        this.notificationTypes = response.data.data;
                        if(this.notificationTypes.length > 0){
                            this.message_type = this.notificationTypes[0].type_code
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get notification types")
                    }).finally(() => {
                        this.showLoader = false;
                    })

                } catch (error) {
                    this.showLoader = false;
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get notification types")
                }
            },

            async getNews() {
                try {
                    await Content.getNews(10)
                        .then((response) => {
                        this.news = response.data.news;
                        if(this.news.length > 0){
                            this.news.forEach((newsItem, index) => {
                                this.deepLinkActionsNews.push({ "articleId": newsItem.source_id, "name": newsItem.title });
                            });
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get news")
                    }).finally(() => {
                        this.showLoader = false;
                    })

                } catch (error) {
                    this.showLoader = false;
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get news")
                }
            },

            async getVideos() {
                try {
                    await Content.getVideos(10)
                        .then((response) => {
                            this.videos = response.data.videos;
                            if(this.videos.length > 0){
                            this.videos.forEach((videoItem, index) => {
                                this.deepLinkActionsVideos.push({ "videoId": videoItem.id, "name": videoItem.title });
                            });
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get videos")
                    }).finally(() => {
                        this.showLoader = false;
                    })

                } catch (error) {
                    this.showLoader = false;
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get videos")
                }
            },

            sendNotification: async function () {
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                    var params = {
                         customer_id: this.id_e,
                         message_type: this.message_type,
                         schedule_time: this.schedule_time,
                         language: this.language,
                         title: this.title,
                         message: this.message,
                         csrf_token: this.csrf_token,
                         deep_link: {
                             action: this.deepLinkingAction,
                             fullscreen: "Yes"
                         }
                     };

                    if(this.deepLinkingActionNews != ''){
                        params.deep_link.article_id = this.deepLinkingActionNews;
                    }

                    if(this.deepLinkingActionVideos != ''){
                         params.deep_link.video_id = this.deepLinkingActionVideos;
                    }

                    if(this.deepLinkingActionMDC != ''){
                         params.deep_link.game_id = this.deepLinkingActionMDC;
                    }

                        await Notification.create(params).then((response) => {
                            this.successmsg("Message successfully sent");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Message has not been sent")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Message has not been sent")
                    }
                }
                this.tryingToEdit = false;
            },

            scheduleNotification(){
                this.showSchedule = !this.showSchedule

                if(this.schedule_time){
                    this.schedule_time = moment().add(1, 'hours').format("YYYY-MM-DD HH:mm");
                }else{
                    this.schedule_time = '';
                }
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.title = '';
                this.message = '';
                this.schedule_time = '';
                this.submitted = false;
                this.showSchedule = false;
                this.notificationTypes = [];
                this.deepLinkingAction = '';
                this.deepLinkingActionNews = '';
                this.deepLinkingActionVideos = '';
                this.deepLinkingActionMDC = '';
                this.deepLinkingFullScreen = '';
                this.showDeepLinkingActionNews = false;
                this.showDeepLinkingActionVideos = false;
                this.showDeepLinkingActionMDC = false;
            },

           onChangeScreen(){
                this.showDeepLinkingActionNews = false;
                this.showDeepLinkingActionVideos = false;
                this.showDeepLinkingActionMDC = false;
                this.deepLinkActionsNews = [];
                this.deepLinkActionsVideos = [];
                this.deepLinkActionsMDC = [];

                if(this.deepLinkingAction == 'mdc'){
                    this.showDeepLinkingActionMDC = true;
                    this.deepLinkActionsMDC.push({ "gameId": "motm", "name": "Man of the match" });
                    this.deepLinkActionsMDC.push({ "gameId": "gts", "name": "Guess the score" });

                }
                else if(this.deepLinkingAction == 'news'){
                    this.showDeepLinkingActionNews = true;
                    this.getNews();
                }
                else if(this.deepLinkingAction == 'videos'){
                    this.showDeepLinkingActionVideos = true;
                    this.getVideos();
                }

            }
        }
    };
</script>

<template>
    <b-modal @shown="getNotificationTypes" @hidden="resetProps" v-model="showModal" id="send_notification" title="Send Push Notification" title-class="font-18" centered>
        <form @submit.prevent="sendNotification">
            <template v-if="!showLoader">
                <div class="mb-3">
                    <label class="control-label form-label">Choose Type</label>
                    <select v-model="message_type" class="form-control form-select" type="select">
                        <option v-for="type in notificationTypes" :key="type.type_code" :value="type.type_code">
                            {{ type.type_name }}
                        </option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="control-label form-label">Title</label>
                    <b-form-input v-model="title" :maxlength="40"></b-form-input>
                </div>

                <b-form-group label="Message" label-for="formrow-message-input" class="mb-3">
                        <b-form-textarea
                                v-model="message"
                                rows="3"
                                :maxlength="150"
                                aria-describedby="message-feedback"
                                :class="{
                                    'is-invalid': submitted && $v.message.$error,
                                }">
                        </b-form-textarea>
                        <validationMessages v-if="submitted" :fieldName="'Message'" :validationName="$v.message"></validationMessages>
                </b-form-group>

                <label class="control-label form-label">Deep Linking</label>


                <div class="mb-3">
                    <label class="control-label form-label">Screen</label>
                    <select v-model="deepLinkingAction" class="form-control form-select" type="select" @change="onChangeScreen">
                        <option></option>
                        <option v-for="screen in deepLinkActions" :key="screen.action" :value="screen.action">
                            {{ screen.name }}
                        </option>
                    </select>
                </div>

                <div class="mb-3" v-show="showDeepLinkingActionNews"  >
                    <label class="control-label form-label">Select News</label>
                    <select v-model="deepLinkingActionNews" class="form-control form-select" type="select" >
                         <option v-for="screenNews in deepLinkActionsNews" :key="screenNews.articleId" :value="screenNews.articleId">
                            {{ screenNews.name }}
                         </option>
                    </select>
                </div>

                <div class="mb-3" v-show="showDeepLinkingActionVideos"  >
                    <label class="control-label form-label">Select Video</label>
                    <select v-model="deepLinkingActionVideos" class="form-control form-select" type="select" >
                        <option v-for="screenVideos in deepLinkActionsVideos" :key="screenVideos.videoId" :value="screenVideos.videoId">
                            {{ screenVideos.name }}
                         </option>
                    </select>
                </div>

                <div class="mb-3" v-show="showDeepLinkingActionMDC"  >
                    <label class="control-label form-label">Select Game</label>
                    <select v-model="deepLinkingActionMDC" class="form-control form-select" type="select" >
                        <option v-for="screenMDC in deepLinkActionsMDC" :key="screenMDC.gameId" :value="screenMDC.gameId">
                            {{ screenMDC.name }}
                         </option>
                    </select>
                </div>


                <!--<div class="mb-5">-->
                    <!--<label class="control-label form-label">Full Screen</label>-->
                    <!--<select v-model="deepLinkingFullScreen" class="form-control form-select" type="select">-->
                        <!--<option></option>-->
                        <!--<option v-for="fs in deepLinkFullScreen" :key="fs.key" :value="fs.key">-->
                            <!--{{ fs.value }}-->
                        <!--</option>-->
                    <!--</select>-->
                <!--</div>-->

                <div class="mb-3" v-if="notification_languages.length > 0">
                    <label class="control-label form-label">Language</label>
                    <select v-model="language" class="form-control form-select" name="type" type="select" >
                        <option value="">All</option>
                        <option v-for="lang in notification_languages" :key="lang" :value="lang">{{lang}}</option>
                    </select>
                </div>


                <b-form-checkbox-group>
                    <div class="form-check form-switch form-switch-lg mb-3" >
                        <input v-model="showSchedule"
                               type="checkbox"
                               class="form-check-input"
                               id="showSchedule"
                               @click="scheduleNotification()"
                        />
                        <label class="form-check-label" for="showSchedule">Schedule Notification</label>
                    </div>
                </b-form-checkbox-group>

                <b-form-group v-if="showSchedule">
                    <label class="form-label mb-3">Set date/time</label>
                        <flat-pickr
                            v-model="schedule_time"
                            :config="datetimeconfig"
                            class="form-control"
                        />
                </b-form-group>
                <input type="hidden" name="csrf_token" v-model="csrf_token">
            </template>
            <template v-else>
                <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                    <b-spinner large></b-spinner>
                </div>
            </template>
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="sendNotification" :disabled="tryingToEdit || notificationTypes.length <= 0">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Send</b-button>
        </template>
    </b-modal>
</template>
