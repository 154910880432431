<script>
    import Notification from '@/services/Notification';
    import Devices from '@/services/Devices';
    import Swal from "sweetalert2";

    export default {
        props: {
            device_id: {
                type: String,
                default: null
            },
            device_data:{
                type: Object,
            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                showModal: false,
                deviceDetails: [],
                push_token: '',
                notificationTable: [],
                isBusy: false,
                totalRows: 1,
                limit: 100,
                currentPage: 1,
                perPage: 5,
                pageOptions: [5, 10, 20],
                filter: null,
                filterOn: [],
                sortBy: "device_platform",
                sortDesc: false,
                preferences: [],
                notifTypes: [],
                preferencesTypes: [],
                fields: [
                    {
                        key: "notification_id",
                        label: "Notification ID",
                        sortable: false,
                    },
                    {
                        key: "success",
                        label: "Success",
                        sortable: false,
                    },
                    {
                        key: "message",
                        label: "Message",
                        sortable: false,
                    },
                    {
                        key: "timestamp",
                        label: "Timestamp",
                        sortable: false,
                    },
                ]
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.notificationTable.length;
            },
        },
        methods: {

            filterType(searchType) {
                let obj = this.preferencesTypes.find(x => x.type === searchType);
                return this.preferencesTypes.indexOf(obj);
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            getData(){
                this.getNotifications()
                this.getNotificationTypes()
            },

            async getNotifications(){
                try {
                    this.toggleBusy();
                    var filters = '&push_token='+this.device_data.pushtoken;
                    const response = await Notification.getNotifications(this.page=1, this.limit=100, filters)
                    this.notificationTable = response.data.notifications;
                    this.toggleBusy();
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.notificationTable = []
                }
            },

            async getNotificationPreferences(){
                try {
                    var filter = "device_id="+this.device_data.device_id+"&customer_id="+this.device_data.user_id;
                    await Devices.getNotificationPreferences(filter).then((response) => {

                        if(response.data.types) {
                            this.preferences = response.data.types;
                            this.preferences.forEach((value) => {
                                var notif_type_index = this.filterType(value.type)

                                if(notif_type_index != -1){
                                    this.preferencesTypes[notif_type_index].status = (value.status == 'Y') ? true : false;
                                }
                            })
                        }
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },

            async getNotificationTypes() {
                this.notifTypes = [];
                await Notification.getNotificationTypes()
                    .then((response) => {
                        response.data.data.forEach((value) => {
                            this.preferencesTypes.push({type: value.type_code, status: false, 'type_name' : value.type_name})
                        });

                        this.notifTypes = response.data.data
                        this.getNotificationPreferences()
                    })
            },

            updateNotificationPreferences(notifType){

                try {
                    var types = [];
                    for (const [key, value] of Object.entries(this.preferencesTypes)) {
                        let status_new = '';

                        if(notifType == value.type){
                            if(value.status == true){
                                status_new = 'N'
                            }else{
                                status_new = 'Y'
                            }
                        }else{
                            status_new = (value.status == true) ? 'Y' : 'N'
                        }

                        types.push({type:value.type, status:status_new})
                    }

                    Devices.updatePreferences({
                        device_id: this.device_data.device_id,
                        customer_id: this.device_data.user_id,
                        types: types,
                        csrf_token: this.csrf_token
                    }).then((response) => {

                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        console.log(this.error);
                    }).finally(() => {

                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    console.log(this.error);
                }
            },

            showToken(token){
                Swal.fire({
                    html: "<strong>"+token+"</strong>",
                    showCancelButton: false,
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                })
            },

            closeModal() {
                this.showModal = false;
            },
            resetProps(){
                this.preferencesTypes = []
            }
        }
    };
</script>

<template>
    <b-modal @shown="getData" @hidden="resetProps" v-model="showModal" id="view_device" title="View Device Data" title-class="font-18" size="xl" centered>
        <div class="row">
            <div class="col-6">
                <h5 class="mb-3">General Information</h5>
                <p class="font-size-16 mb-2"><span class="text-strong">Device ID: </span>{{device_data.device_id}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Customer ID: </span>{{device_data.user_id}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Advertising ID: </span>
                    <span v-if="device_data.advertising_id">{{device_data.advertising_id}}</span>
                    <span v-else>N/A</span>
                </p>
                <p class="font-size-16 mb-2"><span class="text-strong">Push Token:</span> <a
                        href="javascript:void(0);"
                        @click="showToken(device_data.pushtoken)"
                        title="View Push Token"
                        v-if="device_data.pushtoken"
                >
                    <i class="uil uil-eye font-size-18"></i>
                </a></p>
                <p class="font-size-16 mb-2"><span class="text-strong">Last Updated : </span>{{device_data.timestamp}}</p>
            </div>
            <div class="col-6">
                <h5 class="mb-3">Device Information</h5>
                <p class="font-size-16 mb-2"><span class="text-strong">MAC Address:</span> <span v-if="device_data.mac_address">{{device_data.mac_address}}</span><span v-else>N/A</span></p>
                <p class="font-size-16 mb-2"><span class="text-strong">App Version: </span>{{device_data.app_version}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Device Platform: </span>{{device_data.device_platform}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Device Os: </span>{{device_data.device_os}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Device Type: </span>{{device_data.device_type}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">5 GHz: </span>{{device_data.five_ghz}}</p>
            </div>
            <div class="col-12">
                <h5 class="mb-3">Notification Statistics</h5>
            </div>
            <div class="col-6">

                    <span class="font-size-16 mb-2 text-strong">Notifications Run Rate: </span>
                    <div class="text-center d-inline me-1" v-for="(notifs) in device_data.notificationRunRate" :key="notifs.token">
                        <i :class="(notifs.success == 'Y') ? 'text-success' : 'text-danger'" class="fa fa-square" :title="notifs.success" ></i>
                    </div>
                <p class="font-size-16 mb-2"><span class="text-strong">Last Successful Notification: </span>
                    <span v-if="device_data.lastSuccessNotif">{{device_data.lastSuccessNotif}}</span>
                    <span v-else>N/A</span>
                </p>
            </div>
            <div class="col-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Total Notifications: </span>{{device_data.totalNotifications}}</p>
                <p class="font-size-16 mb-2 text-strong"><span>Notifications (R/NR): </span>
                    <span class="text-success">{{device_data.Y}}</span>/<span class="text-danger">{{device_data.N}}</span>
                </p>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <h5 class="mb-3">Notifications Preferences</h5>
            </div>
            <div class="col-3" v-for="(notifType, index) in preferencesTypes" :key="notifType.type">
                <b-form-checkbox-group >
                    <div class="form-check form-switch form-switch-lg mb-3" >
                        <input v-model="preferencesTypes[index].status" type="checkbox" class="form-check-input" :id="index" @click="updateNotificationPreferences(notifType.type)" />
                        <label :for="index" class="form-check-label">{{notifType.type_name}}</label>
                    </div>
                    <input type="hidden" name="csrf_token" v-model="csrf_token">
                </b-form-checkbox-group>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <h5>Notifications Table</h5>
            </div>
            <div class="row mt-3">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center fw-normal">
                                Show&nbsp;
                                <b-form-select
                                        v-model="perPage"
                                        size="sm"
                                        :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div
                                id="tickets-table_filter"
                                class="dataTables_filter text-md-end"
                        >
                            <label class="d-inline-flex align-items-center fw-normal">
                                Search:
                                <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                ></b-form-input>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
            </div>
            <div class="col-12">
                <div class="table-responsive mb-0">
                    <b-table
                            :items="notificationTable"
                            :busy="isBusy"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            show-empty
                            empty-text="No Data Found"
                    >

                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="col-12">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                        ></b-pagination>
                    </ul>
                </div>
            </div>
        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>