<script>
    import Customers from '@/services/Customers';
    import { required, email } from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            emailData: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                allow_email_change: process.env.VUE_APP_ALLOW_EMAIL_CHANGE,
                csrf_token: localStorage.getItem('csrf_token'),
                email: '',
                primary: '',
                verified: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                primary_checked: false,
                verified_checked: false,
            };
        },
        validations: {
            email: {
                required,
                email
            }
        },
        methods: {

            isDisabled(){
              return this.allow_email_change ? false : true;
            },

            async editCustomerEmail(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await this.update_primary_email()
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            async update_primary_email(){
                await Customers.updateCustomer(this.$route.params.id,{
                    email: this.email,
                   // primary: this.primary_checked == true ? 'Y' : 'N',
                    csrf_token: this.csrf_token
                }).then((response) => {
                    this.successmsg("Changes has been saved");
                    this.refreshData()
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                })
            },

            async update_verified_email(){
                this.tryingToEdit = true;

                await Customers.verifyEmail({
                    email: this.email,
                    verified: this.verified_checked == true ? 'Y' : 'N',
                    csrf_token: this.csrf_token
                }).then((response) => {
                    this.successmsg("Changes has been saved");
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Changes not saved")
                }).finally(() => {
                    setTimeout(() => {
                        this.refreshData()
                    }, 2000);
                    this.tryingToEdit = false;
                })
            },

            checked_primary(){
                this.primary_checked = this.primary == 'Y' ? true : false;
            },
            checked_verified(){
                this.verified_checked = this.verified == 'Y' ? true : false;
            },
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.tryingToEdit = false;
                this.submitted = false;
                this.email = ''
                this.primary = ''
                this.verified = ''

            },
            setData(){
                this.email = this.emailData.email
                this.primary = this.emailData.primary
                this.verified = this.emailData.verified
                this.checked_primary()
                this.checked_verified()
            }
        }
    };
</script>

<template>
    <b-modal v-model="showModal" @shown="setData" @hidden="resetProps" id="mail_edit" title="Edit Customer Email" title-class="font-18" centered>
        <form @submit.prevent="editCustomerEmail">

            <b-form-group label="Email" label-for="formrow-email-input" class="mb-3" >
                <b-form-input
                        v-model.trim="email"
                        type="text"
                        id="email"
                        aria-describedby="email-feedback"
                        :disabled="isDisabled()"
                        :class="{
                          'is-invalid': submitted && $v.email.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.email"></validationMessages>
            </b-form-group>

            <b-form-checkbox-group>
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="primary_checked"
                           type="checkbox"
                           class="form-check-input"
                           id="primaryEmail"
                           disabled
                    />
                    <label class="form-check-label" for="primaryEmail">Primary Email</label>
                </div>
            </b-form-checkbox-group>

            <b-form-checkbox-group>
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="verified_checked"
                           type="checkbox"
                           class="form-check-input"
                           id="emailVerified"
                           @change="update_verified_email"
                    />
                    <label class="form-check-label" for="emailVerified">Verified Email</label>
                </div>
            </b-form-checkbox-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editCustomerEmail" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>