<script>

 import Ticketing from "@/services/Ticketing";
 //import VueBarcode from '@chenfengyuan/vue-barcode';
 import VueQrcode from '@chenfengyuan/vue-qrcode';



 export default {
        components:{
        VueQrcode
        },
        props: ['ticketData'],

        data() {
            return {
                showModal: false,
                showLoader: true,
                log_data:'',
            };
        },


        methods: {

            closeModal() {
                this.showModal = false;
            },

            hideLoader(){
               this.showLoader = false;
               }

       },


    };

</script>


<template>
    <b-modal id="season_ticket"  @shown="hideLoader()" size="l" v-model="showModal" title="Season Ticket" title-class="font-18"  >
        <div  v-if="!showLoader" class="modal-body" style="" >
            <div class="text-center logo-cover">
                <h5>{{ticketData.display_name}}</h5>
            </div>

            <div class="row">
               <div class="col-sm-12">
                  <p class="font-size-14 mb-2"><span class="text-strong">Subscription Number:</span> {{ticketData.subscription_number}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Transaction Number:</span> {{ticketData.transaction_number}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Entrance:</span> {{ticketData.ticket_entrance}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Stand:</span> {{ticketData.stand_name}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Section:</span> {{ticketData.ticket_section}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Row:</span> {{ticketData.ticket_row}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Client:</span> {{ticketData.client_name}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Price:</span> {{ticketData.price.toFixed(2)}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Price Type:</span> {{ticketData.price_type_name}}</p>
               </div>
            </div>

              <template>
                <div class="row">
                     <div v-if="ticketData.barcode != '' " class="col-sm-12 text-center" style="padding:0px; margin:0px;">
                        <vue-qrcode :value="ticketData.barcode"  :options="{ width: 200, margin: 0}" ></vue-qrcode><br>
                        <span>{{ticketData.barcode}}</span>
                    </div>
                    <div v-else class="col-sm-12 text-center" >No barcode</div>
                 </div>

              </template>

        </div>


         <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>