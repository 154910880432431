<script>

 import Ticketing from "@/services/Ticketing";
 //import VueBarcode from '@chenfengyuan/vue-barcode';
 import VueQrcode from '@chenfengyuan/vue-qrcode';



 export default {
        components:{ VueQrcode },
        props: ['ticketData'],

        data() {
            return {
                showModal: false,
                showLoader: true,
                log_data:'',
            };
        },


        methods: {

            closeModal() {
                this.showModal = false;
            },

            hideLoader(){
               this.showLoader = false;
               }

       },


    };

</script>


<template>
    <b-modal id="match_ticket"  @shown="hideLoader()" size="l" v-model="showModal" title="Match Ticket" title-class="font-18"  >
    <div  v-if="!showLoader" class="modal-body" style="" >
          <div class="d-flex align-items-baseline">
                <div class="text-center logo-cover">
                   <img   :src="ticketData.match.home_team_logo"  :alt="ticketData.match.home_team_name"   class="rounded-circle w-50" />
                   <p>{{ ticketData.match.home_team_name }}</p>
                </div>
                <div class="text-center d-flex flex-column align-items-center">
                    <div class="d-flex gap-4 align-items-end">
                     <p>{{ticketData.match.date_formatted}}<br>  </p>
                   </div>
                </div>

                <div class="text-center logo-cover">
                    <img  :src="ticketData.match.away_team_logo" :alt="ticketData.match.away_team_name" class="rounded-circle w-50"/>
                    <p>{{ ticketData.match.away_team_name }}</p>
                </div>

          </div>

           <div class="text-center d-flex flex-column align-items-center">
               <div class="d-flex gap-4 align-items-end">
                   {{ ticketData.match.venue }}
               </div>
           </div>

           <div class="row">
               <div class="col-sm-12">
                  <p class="font-size-14 mb-2"><span class="text-strong">Order ID:</span> {{ticketData.order_id}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Order GUID:</span> {{ticketData.order_guid}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Ticket Number:</span> {{ticketData.ticket_number}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Entrance:</span> {{ticketData.ticket_entrance}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Section:</span> {{ticketData.ticket_section}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Row:</span> {{ticketData.ticket_row}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Seat:</span> {{ticketData.ticket_seat}}</p>
                  <!--<p class="font-size-14 mb-2"><span class="text-strong">Barcode:</span> {{ticketData.barcode}}</p> -->
                  <p class="font-size-14 mb-2"><span class="text-strong">Client:</span> {{ticketData.client_name}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Price:</span> {{ticketData.ticket_price.toFixed(2)}}</p>
                  <p class="font-size-14 mb-2"><span class="text-strong">Price Type:</span> {{ticketData.ticket_price_type}}</p>
               </div>
           </div>



          <template>
            <div class="row">
                 <div v-if="ticketData.barcode != '' " class="col-sm-12 text-center" style="padding:0px; margin:0px;">
                    <!--<vue-barcode :value="ticketData.barcode"></vue-barcode> -->
                    <vue-qrcode :value="ticketData.barcode"  :options="{ width: 200, margin: 0}" ></vue-qrcode><br>
                    <span>{{ticketData.barcode}}</span>
                </div>
                <div v-else class="col-sm-12 text-center" >No barcode</div>
             </div>
          </template>

    </div>

     <template v-slot:modal-footer>
        <b-button variant="light" @click="closeModal">Close</b-button>
    </template>
    </b-modal>
</template>