import Http from './Http'

export default {
    async getSocialCustomer(customer_id) {
        return await Http.get(`social/customer/${customer_id}`)
    },
    async getSocialMilestones() {
        return await Http.get('social/milestone')
    },
    async getSocialCustomers(data=""){
        return await Http.get(`/social/customer?${data}`)
    },
     
}


