<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import General from '@/services/General';
    import Customers from '@/services/Customers';
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            customer_id: {
                type: String
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                phone_raw: '',
                phone_country: '',
                phone_type: 'mobile',
                phone_formatted: '',
                primary: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                countries: {},
                prefix: '',
                splitedPhoneStr: '',
                phone_number: '',
                primary_checked: false,
            };
        },
        validations: {
            phone_number: {
                required,
                minLength: minLength(2),
                numeric
            },
        },
        created() {
            this.getCountry()
        },
        methods: {

            async addCustomerPhone(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        await Customers.addPhone({
                            customer_id: this.customer_id,
                            phone_raw: this.phone_number,
                            phone_country: this.phone_country,
                            phone_type: this.phone_type,
                            primary: this.primary_checked == true ? 'Y' : 'N',
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Changes has been saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Changes not saved")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                            this.phone_number = '';
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Changes not saved")
                    }
                }
                this.tryingToEdit = false;
            },

            phoneBase(){
                if(this.phone_country){
                    this.splitedPhoneStr = this.phone_formatted.split(" ");
                    this.splitedPhoneStr.shift();
                    this.phone_number = this.splitedPhoneStr.join('');
                }else{
                    this.phone_number = this.phone_raw;
                }
            },
            checked_primary(){
                this.primary_checked = this.primary == 'Y' ? true : false;
            },
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            async getCountry() {
                try {
                    await General.getCountry({

                    }).then((response) => {
                        this.countries = response.data;
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get countries")
                    }).finally(() => {
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get countries")
                }
            },

            closeModal() {
                this.showModal = false;
            },

        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="phone_add" title="Add Customer Phone" title-class="font-18" centered>
        <form @submit.prevent="addCustomerPhone">
            <label>Phone Number</label>
            <b-input-group class="mb-3">
                <select v-model="phone_country" class="form-select">
                    <option v-for="country in countries" :key="country.alpha2code" :value="country.alpha2code">{{country.alpha2code}} ({{country.calling_code}})</option>
                </select>
                <b-input-group-append>
                    <b-form-group label-for="formrow-phone-input" >
                        <b-form-input
                                v-model.trim="phone_number"
                                type="text"
                                id="phone"
                                aria-describedby="order-feedback"
                                :class="{
                          'is-invalid': submitted && $v.phone_number.$error,
                        }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Phone'" :validationName="$v.phone_number"></validationMessages>
                    </b-form-group>
                </b-input-group-append>
            </b-input-group>

            <b-form-group label="Phone Type" label-for="formrow-phone_type-input" class="mb-3">
                <select v-model="phone_type" class="form-select">
                    <option value="mobile">Mobile</option>
                    <option value="fixed">Fixed</option>
                </select>
            </b-form-group>

            <b-form-checkbox-group>.
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="primary_checked"
                           type="checkbox"
                           class="form-check-input"
                           id="primaryPhone"
                    />
                    <label class="form-check-label" for="primaryPhone">Primary Phone</label>
                </div>
            </b-form-checkbox-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addCustomerPhone" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>