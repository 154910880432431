
import Http from './Http'

export default {
    async getUserDevices(user_id) {
        return await Http.get(`/device/user?user_id=${user_id}`)
    },
    async getDevice(deviceId) {
        return await Http.get(`/device/${deviceId}`)
    },
    async updatePreferences(data) {
        return await Http.patch(`/device/notification/preference`, data)
    },
    async getNotificationPreferences(data) {
        return await Http.get(`/device/notification/preference?${data}`)
    },

}


