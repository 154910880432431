<script>
    import Devices from '@/services/Devices';
    import Swal from "sweetalert2";
    import modalViewDevice from  '@/components/modals/devices/modalViewDevice';

    export default {
        components:{modalViewDevice},
        props: {
            customer_id: String
        },
        data() {
            return {
                device_id: '',
                device_data: {},
                tableDevices: [],
                isBusy: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "device_platform",
                sortDesc: false,
                fields: [
                    {
                        key: "device_platform",
                        label: "Device Platform",
                        sortable: true,
                    },
                    {
                        key: "device_os",
                        label: "Device OS",
                        sortable: true,
                    },
                    {
                        key: "device_type",
                        label: "Device Type",
                        sortable: true,
                    },
                    {
                        key: "pushtoken",
                        label: "Push Token",
                        sortable: false,
                    },
                    {
                        key: "app_version",
                        label: "App Version",
                        sortable: true,
                    },
                    {
                        key: "totalNotifications",
                        label: "Notifications",
                        sortable: false,
                    },
                    "action"
                ]
            }
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableDevices.length;
            }
        },
        mounted() {
            this.getUserDevices()
            this.totalRows = 0;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            async getUserDevices(){
                this.toggleBusy();
                await Devices.getUserDevices(this.$route.params.id)
                    .then(response => {
                        this.tableDevices = response.data.data
                        this.toggleBusy();
                    })
                    .catch(error => {
                        this.toggleBusy();
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
            },

            showToken(token){
                Swal.fire({
                    html: "<strong>"+token+"</strong>",
                    showCancelButton: false,
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                })
            },

            callModalViewDevice(data){
                this.$bvModal.show("view_device");
                this.device_id = data.device_id;
                this.device_data = data;
            }
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive mb-0">
                <b-table
                    :items="tableDevices"
                    :busy="isBusy"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    empty-text="No Data Found"
                >

                    <template v-slot:cell(pushtoken)="data">
                        <a
                            href="javascript:void(0);"
                            @click="showToken(data.item.pushtoken)"
                            title="View Push Token"
                            v-if="data.item.pushtoken.length"
                        >
                            <i class="uil uil-eye font-size-18"></i>
                        </a>
                    </template>

                    <template v-slot:cell(totalNotifications)="data">
                        <div class="text-center text-strong">
                            {{data.item.totalNotifications}} (<span class="text-success" title="Received">{{data.item.Y}}</span>/<span class="text-danger" title="Not Received">{{data.item.N}}</span>)
                        </div>
                    </template>

                    <template v-slot:cell(action)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a
                                        href="javascript:void(0);"
                                        @click="callModalViewDevice(data.item)"
                                        class="px-2 text-primary"
                                        v-b-tooltip.hover
                                        title="View Device"
                                >
                                    <i class="uil uil-eye font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="col-12">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                    ></b-pagination>
                </ul>
            </div>
        </div>

        <!-- MODALS -->
        <modalViewDevice :device_id="this.device_id" :device_data="this.device_data"></modalViewDevice>
        <!-- /MODALS -->
    </div>
</template>
