
import Http from './Http'

export default {
    async getOrders(data = '') {
        return await Http.get(`/orders?${data}`)
    },
    async getOrder(guid) {
        return await Http.get(`/orders/${guid}`)
    },
    async getOrderLegacy(guid, order_type) {
        return await Http.get(`/orders/legacy?guid=${guid}&order_type=${order_type}`)
    },
    async updateOrder(data) {
        return await Http.patch(`/orders`, data)
    },
    async getStatuses() {
        return await Http.get(`/orders/statuses`)
    },

}


