export default {

    sanitizeInput(input) {
        const replacements = {
            "'": "’"
        };

        return input.replace(/['"]/g, (match) => replacements[match]);
    },

    sanitizeObject(data) {
        const sanitizedData = {};

        for (const key in data) {
            if (typeof data[key] === 'string') {
                sanitizedData[key] = this.sanitizeInput(data[key]);
            } else {
                sanitizedData[key] = data[key];
            }
        }

        return sanitizedData;
    },

};