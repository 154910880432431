<script>
    import Ticketing from '@/services/Ticketing';
    import Swal from "sweetalert2";
    import modalViewMatchTicket from "@/components/modals/ticketing/modalViewMatchTicket";
    import modalViewSeasonTicket from "@/components/modals/ticketing/modalViewSeasonTicket";

    export default {
        components: { modalViewMatchTicket, modalViewSeasonTicket },
        props: {
            customer_id: String
        },
        data() {
            return {
                showLoader:false,
                userData: [],
                modalData:[],
                tableSeason: [],
                tableMatch: [],
                totalRows: 1,
                totalRows2: 1,
                currentPage: 1,
                currentPage2: 1,
                perPage: 5,
                perPage2: 5,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filter2: null,
                filterOn: [],
                filterOn2: [],
                sortBy: "barcode",
                sortBy2: "barcode",
                sortDesc: false,
                sortDesc2: false,
                isBusy: false,
                isBusy2: false,
                fields: [
                    {
                        key: "display_name",
                        label: "Season",
                    },
                    {
                        key: "subscription_number",
                        label: "Subscription Number",
                    },
                    {
                        key: "area_name",
                        label: "Section",
                    },
                    {
                        key: "row_name",
                        label: "Row",
                    },
                    {
                        key: "seat_name",
                        label: "Seat",
                    },

                    {
                        key: "price_type_name",
                        label: "Price Type",
                    },
                    {
                        key: "client_name",
                        label: "Client Name",
                    },
                    {
                        key: "price",
                        label: "Price",
                        tdClass: 'text-end',
                    },
                    "action"

                ],
                fields2: [
                    {
                        key: "match.date_formatted",
                        label: "Date",
                    },
                    {
                        key: "match.match_id",
                        label: "Match",
                    },

                    {
                        key: "ticket_section",
                        label: "Section",
                    },
                    {
                        key: "ticket_row",
                        label: "Row",
                    },
                    {
                        key: "ticket_seat",
                        label: "Seat",
                    },
//                    {
//                        key: "ticket_desc",
//                        label: "Ticket Desc",
//                    },
//                    {
//                        key: "area_name",
//                        label: "Area",
//                    },
//                    {
//                        key: "row_name",
//                        label: "Row",
//                    },
//                    {
//                        key: "seat_name",
//                        label: "Seat",
//                    },
//                    {
//                        key: "item_id",
//                        label: "Item Id",
//                    },
//                    {
//                        key: "share_status",
//                        label: "Share Status",
//                    },
//                    {
//                        key: "ticket_number",
//                        label: "Ticket Nr.",
//                    },
                    {
                        key: "client_name",
                        label: "Client Name",
                    },
                    {
                        key: "ticket_price",
                        label: "Price",
                        tdClass: 'text-end',
                    },
                    "action",

                ]
            }
        },
        mounted() {
            this.getUserByCrmId()
            this.getCustomerSeasonTickets()
            this.getCustomerMatchTickets()
            this.totalRows = 0;
            this.totalRows2 = 0;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            onFiltered2(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows2 = filteredItems.length;
                this.currentPage2 = 1;
            },

            async getUserByCrmId(){
                this.toggleBusy();
                await Ticketing.getUserByCrmId(this.$route.params.id)
                    .then(response => {
                        this.userData = response.data.data
                        if(this.userData.providerOriginalData){
                            this.$emit('setProviderData', this.userData.provider, this.userData.providerOriginalData)
                        }

                        this.toggleBusy();
                    })
                    .catch(error => {
                        this.toggleBusy();
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }).finally(() => {

                    });
            },

            async getCustomerSeasonTickets(){
                this.toggleBusy();
                await Ticketing.getCustomerSeasonTickets('show_all=Y&customerID='+this.$route.params.id)
                    .then(response => {
                        this.tableSeason = response.data.data
                        this.totalRows = this.tableSeason.length
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }).
                    finally(() => {
                        this.toggleBusy();
                    });
            },

            async getCustomerMatchTickets(){
                this.toggleBusy();
                await Ticketing.getCustomerMatchTickets('customerID='+this.$route.params.id)
                    .then(response => {
                        if(!response.data.error){
                            this.tableMatch = response.data.data
                            this.totalRows2 = this.tableMatch.length
                        }
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }).
                    finally(() => {
                        this.toggleBusy();
                    });
            },

            async attachTicketingUserToCustomer(){
                Swal.fire({
                    title: "Are you sure you want to attach ticketing user to Customer?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                    input: 'text',
                    inputLabel: 'Customer Number',
                    inputValue: this.userData && this.userData.customerNumber ? this.userData.customerNumber : '',
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to enter customer number!'
                        }
                    }
                }).then((result) => {
                    if (result.value) {
                        const customerNumber = result.value;
                        this.showLoader = true
                        Ticketing.attachTicketingUserToCustomer({
                            customerNumber: customerNumber,
                            customerID: this.$route.params.id
                        })
                            .then((response) => {
                                const res   = response.data.data  ? response.data.data  : false;
                                const error = response.data.error ? response.data.error : '';
                                if(res && error==''){

                                    this.successmsg("Customer Successfully attached")
                                }else{
                                    this.failedmsg("Failed to attach")
                                }
                            })
                            .catch(error => {
                                this.failedmsg('Failed to attach!')
                            }).finally(() => {
                                this.getUserByCrmId();
                                this.showLoader = false
                            });

                    }
                });
            },

            async detachTicketingUserFromCustomer(){
                Swal.fire({
                    title: "Are you sure you want to detach ticketing user to Customer?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes!",
                }).then((result) => {
                    if (result.value) {
                        this.showLoader = true
                        Ticketing.detachTicketingUserFromCustomer({
                            customerID: this.$route.params.id
                        })
                            .then((response) => {
                                const res   = response.data.data  ? response.data.data  : false;
                                const error = response.data.error ? response.data.error : '';
                                if(res && error==''){

                                    this.successmsg("Customer Successfully detached")
                                }else{
                                    this.failedmsg("Failed to detach")
                                }
                            })
                            .catch(error => {
                                this.failedmsg('Failed to detach!')
                            }).finally(() => {
                                this.getUserByCrmId();
                                this.showLoader = false
                            });
                    }
                });
            },

            callModalViewMatchTicket(data) {
                this.$bvModal.show("match_ticket");
                this.modalData = data;
            },
            callModalViewSeasonTicket(data) {
                this.$bvModal.show("season_ticket");
                this.modalData = data;
            },
        }
    }
</script>

<template>
    <div class="row mb-5">
        <div class="col-sm-12 col-md-12" v-if="!showLoader">
            <div class="mb-2">
                <!-- TODO: FOR CM NEEDS TO BE TESTED (NOT FINISHED) / WORKS ONLY FOR TYMES4  -->
                <p class="mb-1"><span class="text-strong">Customer Number :</span>
                    <b-button v-if="userData && userData.hasOwnProperty('customerNumber')"
                              variant="primary" title="Detach Ticketing User" class="btn-sm float-end"
                              @click="detachTicketingUserFromCustomer()">
                                Detach Ticketing User
                    </b-button>
                    <b-button v-else
                              variant="primary" title="Attach Ticketing User" class="btn-sm float-end"
                              @click="attachTicketingUserToCustomer()">
                                Attach Ticketing User
                    </b-button>
                </p>
                <p class="font-size-16">{{ userData && userData.hasOwnProperty('customerNumber') ? userData.customerNumber : null }}</p>
            </div>
        </div>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>

        <div class="col-12 mt-5 mb-5" v-if="tableSeason.length > 0">
            <p class="mb-2 text-strong">Season Tickets</p>

            <div class="table-responsive mb-0 table-container  " style=" overflow: hidden;">
                <b-table
                        :items="tableSeason"
                        :busy="isBusy"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        show-empty
                        empty-text="No Data Found"
                >
                    <template v-slot:cell(price)="data">
                     {{ data.item.price.toFixed(2)}}
                    </template>

                    <template v-slot:cell(action)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a
                                        href="javascript:void(0);"
                                        @click="callModalViewSeasonTicket(data.item)"
                                        class="px-2 text-primary"
                                        v-b-tooltip.hover
                                        title="View Ticket"
                                >
                                    <i class="uil uil-eye font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div v-if="tableSeason.length === 0" class="col-12 mt-5">
            <div class="col-12">
                <p class="mt-2">No Season Tickets Available</p>
            </div>
        </div>
        <div class="col-12" v-if="tableSeason.length > 0">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                    ></b-pagination>
                </ul>
            </div>
        </div>

        <div class="col-12 mt-5" v-if="tableMatch.length > 0">
            <p class="mb-2 text-strong">Match Tickets</p>

            <div class="table-responsive mb-0 table-container">
                <b-table
                        :items="tableMatch"
                        :busy="isBusy2"
                        :fields="fields2"
                        responsive="sm"
                        :per-page="perPage2"
                        :current-page="currentPage2"
                        :sort-by.sync="sortBy2"
                        :sort-desc.sync="sortDesc2"
                        :filter="filter2"
                        :filter-included-fields="filterOn2"
                        @filtered="onFiltered2"
                        show-empty
                        empty-text="No Data Found"
                >


                    <template v-slot:cell(match.match_id)="data">
                        {{data.item.match.home_team_name}} - {{data.item.match.away_team_name}}
                    </template>

                    <template v-slot:cell(ticket_price)="data">
                     {{ data.item.ticket_price.toFixed(2)}}
                    </template>

                   <!--  <template v-slot:cell(barcode)="data">
                       <div>
                          <b-button v-b-popover.click.top='data.item.barcode' class="btn btn-secondary">View</b-button>
                        </div>
                    </template> -->

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>

                    <template v-slot:cell(action)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a
                                        href="javascript:void(0);"
                                        @click="callModalViewMatchTicket(data.item)"
                                        class="px-2 text-primary"
                                        v-b-tooltip.hover
                                        title="View Ticket"
                                >
                                    <i class="uil uil-eye font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>
                </b-table>
            </div>
        </div>
        <div v-if="tableMatch.length === 0" class="col-12 ">
            <div class="col-12">
                <p>No Match Tickets Available</p>
            </div>
        </div>

        <div class="col-12" v-if="tableMatch.length > 0">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage2"
                            :total-rows="totalRows2"
                            :per-page="perPage2"
                    ></b-pagination>
                </ul>
            </div>
        </div>

        <!-- MODALS -->
          <modalViewMatchTicket :ticketData="modalData" ></modalViewMatchTicket>
          <modalViewSeasonTicket :ticketData="modalData" ></modalViewSeasonTicket>
        <!-- END  MODALS -->
    </div>
</template>
